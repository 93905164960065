<template>
    <div id="mRNASeq">
        <div class="container">
            <div style="margin-bottom: 1rem;">
                <h1>Sample Select</h1>
            </div>
            <a-collapse default-active-key="1" :bordered="false">
                <template #expandIcon="props">
                    <a-icon type="caret-right" style="font-size: 18px" :rotate="props.isActive ? 90 : 0"/>
                </template>
                <a-collapse-panel v-for="item in Object.keys(fieldObj)" :key="item" :header="item === 'main_tissues' ? 'Tissues' : item.charAt(0).toUpperCase() + item.slice(1)">
                    <a-tree
                            v-model="params[item]"
                            checkable
                            :tree-data="getTreeData(fieldObj[item],item)"
                    />
                </a-collapse-panel>
            </a-collapse>

            <div class="search-btn">
                <a-button size="large" @click="search()">Search</a-button>
            </div>

            <a-table v-if="tableShow" :columns="columns" :data-source="dataSource" rowKey="biosample"
                     style="margin-top: 1rem; " :pagination="pagination"
                     @change="sampleTableChange" :scroll="{ x: true }">
                <span slot="group" slot-scope="text, record">
                    <a-radio-group name="radioGroup" default-value="uncheck" @change="(e) => {groupChange(e,record)}">
                        <a-radio value="uncheck">uncheck</a-radio>
                        <a-radio value="case">case</a-radio>
                        <a-radio value="control">control</a-radio>
                    </a-radio-group>
                </span>
                <a slot="biosample" :href="`https://www.ncbi.nlm.nih.gov/biosample/?term=${text}`" target="_blank"
                   slot-scope="text">{{ text }}</a>
                <a slot="bioproject" :href="`https://www.ncbi.nlm.nih.gov/bioproject/?term=${text}`" target="_blank"
                   slot-scope="text">{{ text }}</a>
                <a slot="gsm_acc" :href="`https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=${text}`" target="_blank"
                   slot-scope="text">{{ text }}</a>
                <a slot="gse_acc" :href="`https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=${text}`" target="_blank"
                   slot-scope="text">{{ text }}</a>

            </a-table>

            <div v-if="tableShow" class="search-btn" style="text-align: center; margin-top: 1rem">
                <a-button size="large" :loading="queryLoading" @click="query()"
                          style="margin-left: 1rem;width: 133px;height: 53px;">
                    <span style="font-size: 19px; font-weight: 400; color: #FFFFFF; line-height: 22px;">Start</span>
                </a-button>
            </div>
        </div>
    </div>
</template>

<script>
import {fetchFieldValues, fetchSampleList} from "@/request/niu_api.js";
import {submit_deseq2} from "@/request/task.js";
import {syncTaskListStorage} from "@/utils";
import {waitResponseTask} from "@/utils/storage";
import Bus from "@/utils/bus";

export default {
    name: "mRNASeq",
    data() {
        return {
            tableShow: false,
            fieldObj: {},
            detailTissueNotHas: [],
            params: {
                biosample: [],
                bioproject: [],
                gsm_acc: [],
                gse_acc: [],
                breed: [],
                gender: [],
                main_tissues: [],
                detail_tissue: [],
                cell_stage: [],
                age: [],
                infect: [],
                design: []
            },
            currentSearchParams: {},
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showSizeChanger: true,
                showTotal(total) {
                    return `Total ${total} items`
                },
            },
            dataSource: [],
            columns: [
                {title: 'Group', key: 'operation', fixed: 'left', width: 280, scopedSlots: {customRender: 'group'}},
                {
                    title: 'biosample',
                    dataIndex: 'biosample',
                    key: 'biosample',
                    scopedSlots: {customRender: 'biosample'}
                },
                {
                    title: 'bioproject',
                    dataIndex: 'bioproject',
                    key: 'bioproject',
                    scopedSlots: {customRender: 'bioproject'}
                },
                {title: 'gsm_acc', dataIndex: 'gsm_acc', key: 'gsm_acc', scopedSlots: {customRender: 'gsm_acc'}},
                {title: 'gse_acc', dataIndex: 'gse_acc', key: 'gse_acc', scopedSlots: {customRender: 'gse_acc'}},
                {title: 'breed', dataIndex: 'breed', key: 'breed'},
                {title: 'gender', dataIndex: 'gender', key: 'gender'},
                {title: 'main_tissue', dataIndex: 'main_tissue', key: 'main_tissue'},
                {title: 'detail_tissue', dataIndex: 'detail_tissue', key: 'detail_tissue'},
                {title: 'cell_stage', dataIndex: 'cell_stage', key: 'cell_stage'},
                {title: 'age', dataIndex: 'age', key: 'age'},
                {title: 'infect', dataIndex: 'infect', key: 'infect'},
                {title: 'design', dataIndex: 'design', key: 'design'},

            ],
            queryLoading: false,
            // 选择control的sample
            controlSample: []
        }
    },
    mounted() {
        this.getField();
    },
    methods: {
        async getField() {
            const res = await fetchFieldValues();
            // 删除不参与勾选的四列
            delete res.data.biosample;
            delete res.data.bioproject;
            delete res.data.gse_acc;
            delete res.data.gsm_acc;

            this.fieldObj = res.data;
            // detail_tissue里不该包含的 main_tissues 的值
            this.detailTissueNotHas = res.data.main_tissues.map(item => item.main_tissue);
        },
        getTreeData(arr, type) {
            if (type === "main_tissues") {
                return arr.map(item => ({
                        title: item.main_tissue,
                        key: item.main_tissue,
                        children: item.detail_tissues.map(t => ({title: t, key: t})),
                    })
                )
            }

            return arr.map(t => ({title: t, key: t}));
        },
        search() {
            this.controlSample = [];
            this.currentSearchParams = {};
            this.pagination.current = 1;
            this.tableSearchRequest()
        },
        async tableSearchRequest() {
            this.params.detail_tissue = this.params.main_tissues.filter(item => this.detailTissueNotHas.indexOf(item) === -1);
            this.currentSearchParams = JSON.parse(JSON.stringify(this.params))
            delete this.currentSearchParams.main_tissues;

            const res = await fetchSampleList({
                ...this.currentSearchParams,
                page: this.pagination.current,
                limit: this.pagination.pageSize,
                sort: "biosample",
                order: "asc",
            })
            this.dataSource = res.data.data;
            this.pagination.total = res.data.totalNum;

            this.tableShow = true;
        },
        //分页、排序、筛选变化时触发
        sampleTableChange(pagination, filters, sorter) {
            //如果是分页变化
            if (pagination.current !== this.pagination.current) {
                this.pagination.current = pagination.current;
            }
            if (pagination.pageSize !== this.pagination.pageSize) {
                this.pagination.pageSize = pagination.pageSize;
            }
            this.tableSearchRequest();
        },
        // case / control选择发生变化
        groupChange(e, r) {
            switch (e.target.value) {
                case "control":
                    this.controlSample.push({biosample: r.biosample, group: "control"});
                    break;
                case "case":
                    this.controlSample.push({biosample: r.biosample, group: "case"});
                    break;
                default:
                    this.controlSample.forEach((item, i) => {
                        if (item === r.biosample) {
                            this.controlSample.splice(i, 1);
                        }
                    })
                    break;
            }
        },
        query() {
            this.queryLoading = true;

            if (this.controlSample.length > 200) {
                this.$message.error("Group 选择 case 或 control 的样本总量不能大于200条！");
                return;
            }

            if (this.controlSample.length === 0) {
                this.$message.error("请选择 case 或 control 的样本！");
                return;
            }

            let caseNum = 0;
            this.controlSample.forEach(item => {
                if (item.group === "case") {
                    caseNum++
                }
            })

            if (caseNum === this.controlSample.length || caseNum === 0) {
                this.$message.error("Group 不能全为 case 或 control！");
                return;
            }

            const data = {
                sample_arr: this.controlSample,
                parameter: `-a group -g case -j control`,
                email: ""
            };

            submit_deseq2(data).then(res => {
                if (res.code !== 0) {
                    this.$notification.error({
                        message: '异步任务创建失败',
                        description: '差异分析任务创建失败，请重试！',
                    });
                    return;
                }

                const storageInfo = ["差异分析", res.uid, {
                    dispose: "case", //处理组值
                    contrast: "control" //对照组值
                }]

                syncTaskListStorage(...storageInfo);

                //设置需要轮询结果的id
                waitResponseTask(...storageInfo);

                this.$notification.success({
                    message: '异步任务已添加',
                    description: '差异分析任务已加入任务队列，请查看右下角Task',
                });

                Bus.$emit('pollingInterval');

                this.queryLoading = false;
            })
        }
    }
}
</script>

<style scoped lang="scss">
#mRNASeq {
  background: #F9F9FB;
  padding: 1.4rem 8rem;

  .container {
    background: #fff;
    padding: 2rem;

    ::v-deep .ant-collapse {
      background: #fff;

      &-item {
        background: #F9F9FB;
        margin-bottom: 24px;
        border: 0;
        overflow: hidden;

        .ant-collapse-header {
          font-size: 19px;
          font-weight: 600;
        }

        .ant-collapse-content {
          background: #fff;
          margin: 0.3rem 0.65rem 0.65rem 0.65rem;
          //max-height: 10rem;
          //overflow: auto;
        }
      }
    }

    .search-btn {
      display: flex;
      justify-content: center;

      ::v-deep .ant-btn {
        width: 133px;
        height: 53px;
        background: #097F35;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        border: 0;

        span {
          font-size: 19px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 22px;
        }
      }
    }

    ::v-deep .ant-table {
      border: 1px solid #E6E6E6;
    }
  }
}
</style>